import React from "react";
import contactsImg from "../images/contacts.jpg";
import Layout from "../components/Layout.jsx";
import config from "../utils/config";
import ContactForm from "../components/ContactForm.jsx";
import { FormattedMessage, useIntl } from "gatsby-plugin-intl";
import { Helmet } from "react-helmet";
const style = {
  backgroundImage: `url('${contactsImg}')`,
};

const spanStyle = {
  fontWeight: 500,
};
export default ({location }) => (
  <Layout location={location}>
    <section className="ftco-section bg-light">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12">
            <div className="wrapper">
              <div className="row mb-5">
                <div className="col-md-4">
                  <div className="dbox w-100 text-center">
                    <div className="icon d-flex align-items-center justify-content-center">
                      <span className="fa fa-map-marker"></span>
                    </div>
                    <div className="text">
                      <p>
                        <span>
                          <FormattedMessage id="contacts.address" />:
                        </span>{" "}
                        <a href={config.contacts.mapUrl} target="_blank">{config.contacts.address}</a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="dbox w-100 text-center">
                    <div className="icon d-flex align-items-center justify-content-center">
                      <span className="fa fa-phone"></span>
                    </div>
                    <div className="text">
                      <p>
                        <span>
                          <FormattedMessage id="contacts.phone" />:
                        </span>{" "}
                        <a href={`tel:${config.contacts.phone}`}>{config.contacts.phone}</a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="dbox w-100 text-center">
                    <div className="icon d-flex align-items-center justify-content-center">
                      <span className="fa fa-paper-plane"></span>
                    </div>
                    <div className="text">
                      <div className="d-md-flex flex-row justify-content-start">
                        <span className="mr-2 text-dark" style={spanStyle}>
                          <FormattedMessage id="contacts.email" />:
                        </span>{" "}
                        <div className="d-flex flex-column align-items-md-start">
                        <a href={`mailto:${config.contacts.email}`}>
                          {config.contacts.email}
                        </a>
                        <a href="mailto:amministrazione@effe2finishing.com">
                        amministrazione@effe2finishing.com
                        </a>
                        <a href="mailto:graphics@effe2finishing.com">
                        graphics@effe2finishing.com
                        </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row no-gutters">
                <div className="col-md-7">
                  <div className="contact-wrap w-100 p-md-5 p-4">
                    <h3 className="mb-4">
                      <FormattedMessage id="contacts.contact_us" />
                    </h3>
                    <ContactForm />
                  </div>
                </div>
                <div className="col-md-5 d-flex align-items-stretch">
                  <div
                    className="info-wrap w-100 p-5 img d-none d-md-block"
                    style={style}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);
