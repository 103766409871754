import React from "react";
import config from "../utils/config";
import { FormattedMessage } from "gatsby-plugin-intl";
import axios from "axios";

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      statusKO: null,
      statusOK: null,
      name: null,
      subject: null,
      message: null,
      email: null,
    };
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  onChange(e) {
    const { name, value } = e.currentTarget;
    this.setState({ [name]: value, statusKO: null, statusOK: null });
  }
  async handleOnSubmit(e) {
    e.preventDefault();
    const form = e.target;
    this.setState({ submitting: true });
    var data = new FormData();
    data.append("name", this.state.name);
    data.append("subject", this.state.subject);
    data.append("message", this.state.message);
    data.append("email", this.state.email);
    try {
      const result = await axios({
        method: "post",
        url: config.contacts.getformUrl,
        data,
      });
      this.setState({
        name: "",
        subject: "",
        message: "",
        email: "",
        statusOK: true,
        submitting: false,
      });
      //console.log(this.state);
    } catch (error) {
      this.setState({
        statusKO: true,
        submitting: false,
      });
    }
  }

  render() {
    const inputs = [
      {
        name: "name",
        label: "contacts.name",
        type: "text",
        size: 6,
      },
      {
        name: "email",
        label: "contacts.email",
        type: "email",
        size: 6,
      },
      {
        name: "subject",
        label: "contacts.subject",
        type: "text",
        size: 12,
      },
    ];
    return (
      <form
        onSubmit={this.handleOnSubmit}
        id="contactForm"
        name="contactForm"
        className="contactForm"
      >
        <div className="row">
          {inputs.map((item) => {
            return (
              <div className={`col-md-${item.size}`}>
                <div className="form-group">
                  <label className="label" for={item.name}>
                    <FormattedMessage id={item.label} />
                  </label>
                  <input
                    value={this.state[item.name]}
                    onChange={this.onChange}
                    type={item.type}
                    className="form-control"
                    name={item.name}
                    id={item.name}
                    required={true}
                    disabled={this.state.submitting}
                  />
                </div>
              </div>
            );
          })}
          <div className="col-md-12">
            <div className="form-group">
              <label className="label" for="#">
                <FormattedMessage id="contacts.message" />
              </label>
              <textarea
                name="message"
                value={this.state.message}
                onChange={this.onChange}
                className="form-control"
                id="message"
                cols="30"
                rows="4"
                required={true}
                disabled={this.state.submitting}
              ></textarea>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <button
                type="submit"
                disabled={this.state.submitting}
                className="btn btn-primary"
              >
                <FormattedMessage id="contacts.send" />
              </button>
              <div className="submitting"></div>
            </div>
          </div>
          <div className="col-md-12">
            {this.state.statusOK == true ? (
              <strong className="text-success">
                <FormattedMessage id="contacts.status_ok" />
              </strong>
            ) : null}
            {this.state.statusKO == true ? (
              <strong className="text-danger">
                <FormattedMessage id="contacts.status_ko" />
              </strong>
            ) : null}
          </div>
        </div>
      </form>
    );
  }
}
